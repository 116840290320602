import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Scissors, 
  Droplet, 
  Leaf, 
  HeartHandshake,
  Star, 
  Wand2, 
  ChevronRight, 
  ChevronDown
} from 'lucide-react';

const servicesData = [
  {
    id: 1,
    title: "Makeup",
    icon: Scissors,
    shortDescription: "Enhance your natural beauty and take your look to the next level.",
    longDescription: "Our makeup service is designed to bring out the best in you. Whether it's for a special occasion or a day-to-day look, our makeup artists will ensure you look your absolute best with a flawless finish.",
    duration: "45-60 mins",
    price: "$50 - $80",
    benefits: [
      "Personalized makeup consultation",
      "Flawless application",
      "Special occasion or everyday looks",
      "High-quality products"
    ],
    image: "/G7.jpg"
  },
  {
    id: 2,
    title: "Nail Design",
    icon: Star,
    shortDescription: "Make a statement with captivating nail designs.",
    longDescription: "Indulge in creative and unique nail designs that reflect your style. From intricate patterns to bold colors, our nail artists are skilled in transforming your nails into a work of art.",
    duration: "30-45 mins",
    price: "$25 - $45",
    benefits: [
      "Customized nail designs",
      "Gel and acrylic options",
      "Artistic patterns",
      "Long-lasting finish"
    ],
    image: "/G10.jpg"
  },
  {
    id: 3,
    title: "Hair Braids",
    icon: Droplet,
    shortDescription: "Get ready to turn heads with stunning hair braids.",
    longDescription: "Elevate your look with beautiful braids. Whether you want simple braids or intricate patterns, our stylists specialize in creating braids that suit your style and personality.",
    duration: "60-90 mins",
    price: "$40 - $70",
    benefits: [
      "Personalized consultation",
      "Variety of braid styles",
      "Comfortable and secure braids",
      "Long-lasting results"
    ],
    image: "/G1.jpg"
  },
  {
    id: 4,
    title: "Pedicure & Manicure",
    icon: HeartHandshake,
    shortDescription: "Get pampered and polished with luxurious pedicure and manicure services.",
    longDescription: "Pamper yourself with a relaxing pedicure and manicure. From nail care to soothing massages, our expert team will ensure your hands and feet look and feel amazing.",
    duration: "45-60 mins",
    price: "$35 - $60",
    benefits: [
      "Hand and foot massage",
      "Cuticle care",
      "Polish application",
      "Nail art options"
    ],
    image: "/G12.jpg"
  },
  {
    id: 5,
    title: "Hair Cut & Hair Dye",
    icon: Wand2,
    shortDescription: "Get a fresh new look with our haircut and hair dye services.",
    longDescription: "Refresh your look with a stylish haircut or vibrant hair color. Our stylists are trained in the latest trends and techniques, ensuring you leave with a fresh, bold style.",
    duration: "60-90 mins",
    price: "$50 - $90",
    benefits: [
      "Personalized consultation",
      "Precision haircuts",
      "Vibrant and long-lasting hair dye",
      "Professional styling finish"
    ],
    image: "/G17.jpg"
  },
  {
    id: 6,
    title: "Eyelash Extension",
    icon: Leaf,
    shortDescription: "Get ready to flutter your lashes with our Eyelash Extension service.",
    longDescription: "Enhance your eyes with our luxurious eyelash extensions. Our experts provide the perfect lash look, from subtle to dramatic, ensuring a natural and beautiful finish.",
    duration: "45-60 mins",
    price: "$60 - $100",
    benefits: [
      "Custom lash design",
      "Long-lasting results",
      "Natural and voluminous lashes",
      "Professional application"
    ],
    image: "/G14.jpg"
  }
];

const ServiceDetails = () => {
  const [selectedService, setSelectedService] = useState(servicesData[0]);
  const [mobileActiveId, setMobileActiveId] = useState(null);

  const handleServiceChange = (service) => {
    setSelectedService(service);
  };

  const handleMobileToggle = (service) => {
    setMobileActiveId(mobileActiveId === service.id ? null : service.id);
    setSelectedService(service);
  };

  const slideVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        type: 'spring',
        stiffness: 200,
        damping: 20
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.3 }
    }
  };

  return (
    <div className=" bg-gradient-to-br from-gray-50 to-gray-100 py-20 px-4 md:px-8 lg:px-16 font-roboto">
      <div className="max-w-6xl mx-auto grid md:grid-cols-3 gap-8">
        {/* Desktop Navigation */}
        <div className="hidden md:block md:col-span-1">
          <h2 className="text-4xl font-bold my-6 text-gray-900">
            Our Services
          </h2>
          <div className="space-y-4">
            {servicesData.map((service) => (
              <motion.button
                key={service.id}
                onClick={() => handleServiceChange(service)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`w-full flex items-center p-4 rounded-lg transition-all shadow-md 
                  ${selectedService.id === service.id
                    ? 'bg-yellow-500 text-black'
                    : 'bg-white text-gray-800 hover:bg-yellow-500 hover:text-black'
                  }`}
              >
                <service.icon className="mr-4" size={24} />
                <span className="font-medium truncate">{service.title}</span>
                {selectedService.id === service.id && (
                  <ChevronRight className="ml-auto" size={20} />
                )}
              </motion.button>
            ))}
          </div>
        </div>

        {/* Mobile Navigation */}
        <div className="block md:hidden text-center">
          <h2 className="text-4xl font-bold mb-10 text-gray-900">
            Our Services
          </h2>
          <div className="space-y-4">
            {servicesData.map((service) => (
              <div key={service.id} className="bg-gray-100 rounded-xl shadow-md overflow-hidden">
                <button
                  onClick={() => handleMobileToggle(service)}
                  className="w-full flex items-center p-4 text-black transition-all"
                >
                  <service.icon className="mr-4" size={24} />
                  <span className="font-medium truncate">{service.title}</span>
                  <ChevronDown
                    className={`ml-auto transition-transform ${mobileActiveId === service.id ? 'rotate-180' : ''}`}
                    size={20} 
                  />
                </button>
                <AnimatePresence>
                  {mobileActiveId === service.id && (
                    <motion.div
                      variants={slideVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      className="p-6 bg-white"
                    >
                      <motion.img
                        src={service.image}
                        alt={service.title}
                        className="w-full h-64 object-cover rounded-lg mb-10"
                        whileHover={{ scale: 1.05 }}
                        transition={{ duration: 0.3 }}
                      />
                      <p className="text-gray-700 mb-4 text-sm leading-loose ">
                        {service.longDescription}
                      </p>
                      
                      <div>
                        <span className="block font-semibold text-yellow-600 text-lg mb-4">Benefits</span>
                        <ul className="space-y-2 text-gray-700 text-sm">
                          {service.benefits.map((benefit, index) => (
                            <li key={index} className="flex items-start text-left">
                              <span className="mr-2 text-yellow-600 text-lg">•</span>
                              {benefit}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </div>

        {/* Service Details for Desktop */}
        <div className="hidden md:block md:col-span-2">
          <AnimatePresence mode="wait">
            <motion.div
              key={selectedService.id}
              variants={slideVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="bg-white rounded-xl shadow-lg p-6 md:p-10"
            >
              <div className="grid md:grid-cols-2 gap-6 items-start">
                <motion.img
                  src={selectedService.image}
                  alt={selectedService.title}
                  className="w-full h-full object-cover rounded-lg"
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                  style={{ aspectRatio: '3/4' }}
                />

                <div className='ml-6'>
                  <h3 className="text-2xl font-bold mb-4 text-gray-900">
                    {selectedService.title}
                  </h3>
                  <p className="mb-4 text-yellow-600 italic">
                    {selectedService.shortDescription}
                  </p>
                  <p className="mb-6 text-gray-700">
                    {selectedService.longDescription}
                  </p>
                 
                  <div>
                    <span className="block mb-2 font-semibold text-yellow-600">Benefits</span>
                    <ul className="space-y-2 text-gray-700">
                      {selectedService.benefits.map((benefit, index) => (
                        <li key={index} className="flex items-center">
                          <span className="mr-2 text-yellow-600">•</span>
                          {benefit}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;