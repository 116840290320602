import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Flower, Phone, MapPin, Mail } from 'lucide-react';
import { FaInstagram, FaFacebookF, FaTiktok } from 'react-icons/fa';

const Footer = () => {
  const colors = {
    background: 'bg-gray-950',
    text: 'text-white',
    accent: 'text-yellow-500',
    button: 'bg-yellow-500 hover:bg-yellow-600 text-gray-900'
  };

  const socialLinks = [
    { icon: FaInstagram, href: "https://www.instagram.com/glow_city1/?hl=en" },
    { icon: FaFacebookF, href: "https://www.facebook.com/glowcity1/" },
    { icon: FaTiktok, href: "https://www.tiktok.com/@glow_city1" }
  ];

  const quickLinks = [
    { to: "/", label: "Home" },
    { to: "/services", label: "Services" },
    { to: "/gallery", label: "Gallery" },
    { to: "/contact", label: "Contact" }
  ];

  const contactInfo = [
    { icon: Phone, text: "+251-9 67 43 30 78", type: "Phone" },
    { icon: MapPin, text: "Bole next to MK Restaurant Addis Ababa Ethiopia", type: "Address" },
    { icon: Mail, text: "info@glowcity1.com", type: "Email" }
  ];

  return (
    <footer className={`${colors.background} py-8 sm:py-10`}>
      <div className="container mx-auto px-4 sm:px-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-10 lg:gap-12">
          {/* Logo and Description */}
          <div className="sm:col-span-2 lg:col-span-1">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="text-xl sm:text-2xl font-semibold tracking-wider mb-4"
            >
              <Link to="/" className={`${colors.accent} hover:opacity-80 transition-opacity flex items-center`}>
                <Flower className="mr-2" size={28} />
                Glowcity
              </Link>
            </motion.div>
            <p className={`${colors.text} mb-6 font-light text-sm sm:text-base max-w-md`}>
              Your one-stop destination for vibrant and innovative beauty experiences. Discover the beauty of GlowCity Hair & Nail Bar with every service.
            </p>
            <div className="flex space-x-4">
              {socialLinks.map((social, index) => (
                <motion.a
                  key={index}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.9 }}
                  className={`${colors.accent} hover:opacity-75 transition-colors`}
                >
                  <social.icon size={20} />
                </motion.a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div className="sm:col-span-1">
            <h4 className={`text-lg sm:text-xl ${colors.accent} font-semibold mb-4`}>
              Quick Links
            </h4>
            <div className="space-y-2 sm:space-y-3">
              {quickLinks.map((link, index) => (
                <motion.div
                  key={index}
                  whileHover={{ x: 10 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <Link
                    to={link.to}
                    className={`${colors.text} hover:opacity-75 transition-colors flex items-center text-sm sm:text-base`}
                  >
                    <span className="mr-2">→</span>
                    {link.label}
                  </Link>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Contact Information */}
          <div className="sm:col-span-1">
            <h4 className={`text-lg sm:text-xl ${colors.accent} font-semibold mb-4`}>
              Contact Us
            </h4>
            <div className="space-y-3 sm:space-y-4">
              {contactInfo.map((contact, index) => (
                <div key={index} className="flex items-start">
                  <contact.icon className={`${colors.accent} mr-3 mt-1 flex-shrink-0`} size={16} />
                  <div>
                    <p className={`${colors.text} text-xs sm:text-sm opacity-70`}>{contact.type}</p>
                    <p className={`${colors.text} text-sm sm:text-base break-words`}>{contact.text}</p>
                  </div>
                </div>
              ))}
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => window.location.href = 'tel:+251967433078'}
              className={`${colors.button} py-2 px-4 sm:px-6 rounded-full hover:opacity-90 transition-all mt-4 sm:mt-6 flex items-center text-sm sm:text-base`}
            >
              <Phone className="mr-2" size={16} />
              Call Us
            </motion.button>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 sm:mt-12 pt-4 sm:pt-6 text-center border-t border-gray-700">
          <p className={`${colors.text} text-xs sm:text-sm`}>
            © {new Date().getFullYear()} <span className={colors.accent}>GlowCity Hair & Nail Bar.</span> Developed By{' '}
            <a href="https://inernett.com" className="text-yellow-500 hover:text-yellow-600" target="_blank" rel="noopener noreferrer">
              <span>INERNETT</span>
            </a>
            .
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;