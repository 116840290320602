import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X, Flower, Scissors, Calendar, Phone, GalleryHorizontal } from 'lucide-react';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const colors = {
    primary: {
      background: 'bg-gray-950',
      text: 'text-yellow-500 font-roboto',
      accent: 'text-amber-600', 
      button: 'bg-amber-400',
      buttonText: 'text-black',
      border: 'border-cool-gray-200',
      subtle: 'text-cool-gray-500'
    },
    mobile: {
      background: 'bg-gradient-to-br from-white via-gray-50 to-gray-100',
      text: 'text-cool-gray-700 font-serif',
      textAccent: 'text-amber-700',
      titleGradient: 'bg-gradient-to-r from-amber-600 to-cool-gray-800 bg-clip-text text-yellow-500 capitalize'
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Mobile menu effect
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const navLinks = [
    { to: "/", label: "Home", icon: Flower },
    { to: "/services", label: "Services", icon: Scissors },
    { to: "/gallery", label: "Gallery", icon: GalleryHorizontal },
    { to: "/contact", label: "Contact", icon: Calendar }
  ];

  const menuVariants = {
    hidden: { 
      opacity: 0, 
      y: "-100%",
      transition: { duration: 0.3 }
    },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.3 }
    }
  };

  const linkVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { duration: 0.3 }
    }
  };

  return (
    <>
      <motion.header 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={`
          fixed top-0 left-0 right-0 
          ${colors.primary.background} 
          ${colors.primary.text} 
          py-4 z-40 
          transition-all duration-300 
          ${isScrolled ? 'bg-opacity-95 backdrop-blur-md shadow-lg' : ''}
        `}
      >
        <div className="container mx-auto flex justify-between items-center px-4 lg:px-6">
          <motion.div 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="text-xl sm:text-2xl font-semibold tracking-wider"
          >
            <Link 
              to="/" 
              className={`${colors.mobile.titleGradient} hover:opacity-80 transition-opacity flex items-center space-x-2`}
            >
              Glowcity
            </Link>
          </motion.div>

          <nav className="hidden md:flex items-center">
            <div className="flex space-x-4 lg:space-x-6 xl:space-x-8 mr-4">
              {navLinks.map((link, index) => (
                <motion.div
                  key={link.to}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Link 
                    to={link.to} 
                    className={`
                      ${colors.primary.text} 
                      hover:${colors.primary.accent} 
                      flex items-center 
                      space-x-2 
                      transition-all 
                      duration-300 
                      group 
                      relative 
                      hover:scale-105
                      text-sm lg:text-base
                    `}
                  >
                    <link.icon 
                      className={`${colors.primary.subtle} group-hover:${colors.primary.accent} transition-colors`} 
                      size={18} 
                    />
                    <span className="relative">
                      {link.label}
                      <span className={`absolute -bottom-1 left-0 w-0 group-hover:w-full h-0.5 ${colors.primary.accent} transition-all duration-300`}></span>
                    </span>
                  </Link>
                </motion.div>
              ))}
            </div>
            
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => window.location.href = 'tel:+251967433078'}
              className={`
                ${colors.primary.button} 
                ${colors.primary.buttonText}
                p-2
                rounded-full 
                hover:opacity-90 
                transition-all 
                flex items-center 
                justify-center
                shadow-md 
                ${colors.primary.border}
                w-10 h-10
              `}
              aria-label="Call Now"
            >
              <Phone className="text-black" size={20} />
            </motion.button>
          </nav>

          <button 
            onClick={toggleMobileMenu}
            className={`${colors.primary.text} focus:outline-none md:hidden`}
            aria-label="Toggle Menu"
          >
            {isMobileMenuOpen ? (
              <X size={28} className={colors.primary.subtle} />
            ) : (
              <Menu size={28} className={colors.primary.subtle} />
            )}
          </button>
        </div>
      </motion.header>

      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={menuVariants}
            className={`
              fixed inset-0 
              ${colors.mobile.background}
              ${colors.mobile.text}
              z-50 md:hidden 
              flex flex-col
              p-6
              overflow-y-auto
            `}
          >
            <button 
              onClick={toggleMobileMenu}
              className={`absolute top-6 right-6 ${colors.primary.subtle} hover:opacity-80 z-60`}
              aria-label="Close Menu"
            >
              <X size={32} />
            </button>

            <div className="flex flex-col items-center justify-center h-full space-y-8 font-roboto">
              {navLinks.map((link, index) => (
                <motion.div
                  key={link.to}
                  variants={linkVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  transition={{ delay: index * 0.1 }}
                >
                  <Link 
                    to={link.to} 
                    onClick={toggleMobileMenu}
                    className="text-2xl hover:opacity-80 flex items-center space-x-4 transition-colors hover:scale-105"
                  >
                    <link.icon size={28} className={colors.primary.subtle} />
                    <span>{link.label}</span>
                  </Link>
                </motion.div>
              ))}
              
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => window.location.href = 'tel:+251967433078'}
                className={`
                  ${colors.primary.button}
                  ${colors.primary.buttonText}
                  p-3
                  rounded-full
                  hover:opacity-90
                  transition-all
                  flex items-center
                  justify-center
                  shadow-md
                  ${colors.primary.border}
                  w-12 h-12
                `}
                aria-label="Call Now"
              >
                <Phone className="text-black" size={24} />
              </motion.button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Header;